<template>
    <footer class="s-footer">
        <div class="s-footer-container">
            <div class="s-footer-row ">
                <a href="/"> </a>
            </div>
            <div class="s-footer-row">
                <div class="s-footer-column">
                    <ul class="f-pr">
                        <li><strong>Navigate</strong></li>
                        <li><a href="#/page/home" aria-current="page">Home</a></li>
                        <li><a href="#/collection">Collection</a></li>
                        <li><a href="#/page/about_us">About</a></li>
                        <li><a href="#/contact">Contact</a></li>
                    </ul>
                </div>
                <div class="s-footer-column">
                    <ul class="f-pr">
                        <li><strong>Need help?</strong></li>
                        <!-- <li><a href="#/content/returns-exchange">Returns & Exchange Policy</a></li> -->
                        <li><a href="#/content/privacy-policy">Privacy Policy</a></li>
                        <li v-if="!userInfo"><a @click="openSigninPop()" >Login</a></li>
                        <li v-if="userInfo.id"><a href="#/page/myaccount" >My Account</a></li>
                        <li><a href="#/book_an_appointment" >Book an Appointment</a></li>
                    </ul>
                </div>
                <div class="s-footer-column">
                    <ul class="f-pr">
                        <li><strong>Social</strong></li>
                        <li><a target="_blank" href="https://www.instagram.com/meethasyk/?hl=en" rel="noopener">Instagram</a></li>
                        <li><a target="_blank" href="https://www.facebook.com/meethasyk" rel="noopener">Facebook</a></li>
                    </ul>
                </div>
                <div class="s-footer-column">

                </div>
                <div class="s-footer-column">
                    <div class="s-badge-logo"> <img src="@/assets/logo.png" alt=""> </div>
                </div>
            </div>
            <div class="s-footer-row">
                <div class="s-footer-column">
                    <p class="f-ps">All rights reserved — 2020 © Meethas</p>
                </div>
                <div class="s-footer-column">
                    <p class="f-ps"> <a href="#/content/terms-and-conditions">Terms &amp;
                            Conditions</a> </p>
                </div>
                <div class="s-footer-column">
                    <p class="f-ps"> <a href="https://shopeng.online/" target="_blank" rel="noopener">Powered by Shopeng
                            </a> </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data(){
        return{
            count: 0.1,
            userInfo:{},
        }
    },
    created(){
        this.userInfo = this.$store.state.customer
        this.init()
    },
    methods:{
        init(){
            // setInterval(() => {
            //     setTimeout(() => {
            //         if(this.count >= 360){
            //             this.count = 0
            //         }
            //         this.count += 0.1
            //         document.getElementById("s-badge-outline").style.transform = 'rotate('+this.count+'deg)'
            //     },500)
            // },10)
        },
        openSigninPop(){
            document.getElementById('signinContainer').classList.add('showSigninContainer'); 
        },
    }
}
</script>

<style scoped>
.s-footer-row:nth-child(1) img{
    width: 45%;
    margin: 0 auto;
    height: 50px !important;
}
.s-footer{
    z-index: 6;
}
</style>